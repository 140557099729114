<div class="list-violation-stage">
  <div class="number-counter">{{ shorthandName() }}</div>

  <div class="list-item-violation-stage">
    @if (showEdit()) {
      <div class="edit-mode-container">
        <form (ngSubmit)="onTemplateSave()" [formGroup]="form">
          <div class="form-row">
            <app-roam-input formControlName="stageName" name="Stage Name" />
            <app-roam-select
              isMultiple
              panelClass="roam-checkbox-panel"
              formControlName="templateIds"
              placeholder="Notification Template"
              [items]="templateOptions" />
            <div
              (click)="delete.emit(true)"
              class="trash-icon d-flex"
              matTooltip="Delete this stage"
              matTooltipPosition="above"
              inlineSVG="assets/svg/trash-red-icon.svg"></div>
          </div>

          <div class="set-grid set-grid-3 form-grid">
            <div>
              <mat-checkbox
                class="w-full mb-10"
                formControlName="withFineAmount">
                Letter Adds Fine
              </mat-checkbox>
              <app-roam-input
                class="full"
                formControlName="fineAmount"
                name="Fine Amount" />
            </div>
            <div>
              <mat-checkbox
                class="w-full mb-10"
                formControlName="triggersAbbatement">
                Triggers Abbatement
              </mat-checkbox>
              <mat-checkbox
                class="w-full mb-10"
                formControlName="automaticDriveThru">
                Automatic Drive Thru
              </mat-checkbox>
              <mat-checkbox class="w-full" formControlName="repeatContinuously">
                Repeat Continuously
              </mat-checkbox>
            </div>
            <div>
              <mat-checkbox
                class="w-full mb-10"
                formControlName="markInsurance">
                Mark Insurance
              </mat-checkbox>
              <mat-checkbox class="w-full mb-10" formControlName="markLeasing">
                Mark Leasing
              </mat-checkbox>
            </div>
          </div>

          <div class="edit-actions-group">
            <span (click)="onCancel()" class="text-btn-underline fw-500">
              Cancel
            </span>
            <app-roam-button label="Save" />
          </div>
        </form>
      </div>
    } @else {
      <div class="display-mode-container">
        <div class="stage-name">{{ item.stageName }}</div>
        @if (templateLabel(); as labelText) {
          <p class="template-name">
            <span>{{ labelText | truncate: 25 }}</span>
            @if (labelText.length > 25) {
              <a
                (click)="toggleShowEdit(true)"
                class="roam-text-link roam-text-xs">
                Read more
              </a>
            }
          </p>
        } @else {
          <ng-template [ngTemplateOutlet]="emptyTemplate" />
        }
        <div class="menu-wrapper">
          <button
            [matMenuTriggerFor]="contextMenu"
            class="more-button"
            mat-icon-button
            type="button">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
      </div>
    }
  </div>
</div>

<mat-menu class="violation-stage-item-menu-trigger" #contextMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="toggleShowEdit(true)">
      <div class="align-center gap-8 font-14 fw-500">
        <div class="d-flex" inlineSVG="assets/svg/edit-black.svg"></div>
        <div>Edit</div>
      </div>
    </button>
    <button mat-menu-item (click)="delete.emit(true)">
      <div class="delete-btn align-center gap-8 font-14 fw-500">
        <div class="d-flex" inlineSVG="assets/svg/trash-red-icon.svg"></div>
        <div>Delete</div>
      </div>
    </button>
  </ng-template>
</mat-menu>

<ng-template #emptyTemplate>
  <div>
    <button
      mat-button
      #trigger="matMenuTrigger"
      class="roam-popover-button"
      [matMenuTriggerFor]="popoverOverlay">
      <mat-icon>add</mat-icon>
      <label>Add Template</label>
    </button>

    <mat-menu classList="roam-popover-panel" #popoverOverlay="matMenu">
      <div class="roam-popover-inner" mat-menu-item roamNoPropagation>
        <div class="popover-content checkbox-list">
          @for (opt of templateOptions; track opt.id) {
            <div class="checkbox-wrapper">
              <mat-checkbox
                [checked]="controls.templateIds.value.includes(opt.id)"
                (change)="onCheckChange(opt.id, $event)"
                [value]="opt.id">
                {{ opt.name }}
              </mat-checkbox>
            </div>
          }
        </div>
        <div class="popover-footer">
          <div class="actions-group">
            <a type="button" (click)="trigger.closeMenu()" class="cancel-btn">
              <span>Cancel</span>
            </a>
            <button
              (click)="onTemplatesAdd(trigger)"
              type="button"
              class="btn btn-primary mr-0">
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</ng-template>
