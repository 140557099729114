<div>
  <app-global-form-tabs [tabs]="tabs" [(tabSelected)]="tab" />

  <hr />

  <ng-container [ngSwitch]="tab">
    <app-global-form-association-compliance-violations-stages
      *ngSwitchCase="'violations stages'" />
    <ng-container *ngSwitchCase="'violations templates'">
      @if (associationDetail; as detail) {
        <app-violation-templates-accordion [propertyId]="detail.id" />
      }
    </ng-container>
    <app-global-form-association-compliance-inspections
      *ngSwitchCase="'inspections'" />
    <app-global-form-association-compliance-violations-category
      *ngSwitchCase="'violations category'" />
    <app-global-form-association-compliance-architectural-requests
      *ngSwitchCase="'architectural requests'" />
  </ng-container>
</div>
