<div class="justify-between align-center mb-30">
  <h1 class="font-24px fw-600">Resident Users</h1>

  <roam-icon
    mat-dialog-close
    class="c-pointer"
    name="close"
    size="28" />
</div>

<app-container-dialog-set (onSave)="formSubmit.emit($event)">
  <div container>
    <div class="table-heading">
      <p class="title">List of Residents</p>
    </div>
    <app-users-table
      (rowChange)="onRowChange($event)"
      (removeRow)="removeUser($event)"
      [columnDefs]="usersColDefs()"
      [dataSource]="users()"
      [showPagination]="false" />
    <app-search-users-dropdown-button
      class="mt-20"
      buttonLabel="Add Resident"
      (selectionsSubmit)="addUsers($event)" />
  </div>
</app-container-dialog-set>
