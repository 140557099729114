<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <label class="boldText">Select the amenities and services available</label>

    <div class="set-grid set-grid-3 gap-col-20 pt-20">
      <div class="filter-list" *ngFor="let options of form.controls | keyvalue">
        <mat-checkbox class="check__file" formControlName="{{ options.key }}">
          {{ options.key | uncamel }}
        </mat-checkbox>
      </div>
    </div>
  </ng-container>
</app-container-dialog-set>
  