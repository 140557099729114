import { Component, Input, inject, signal } from "@angular/core";
import { PropertyStore } from "./data-access/property.store";
import { IVendor } from "@app/shared/interfaces/vendor.interface";
import { GlobalFormDialogService } from "../../../container/global-form-dialog/global-form-dialog.service";

const SaveType = {
  save: 'save',
  continue: 'continue',
};

@Component({
  selector: "app-global-form-contact-form-property",
  templateUrl: "./global-form-contact-form-property.component.html",
  styleUrls: ["./global-form-contact-form-property.component.scss"],
})
export class GlobalFormContactVendorPropertyComponent {
  @Input()
  set dataVendor(data: IVendor) {
    this.store.resetState();
    data.linkedProperties?.forEach((el) => {
      this.store.addOne(el);
    });
  }

  isLoading = signal<boolean>(false);

  store = inject(PropertyStore);
  dialogService = inject(GlobalFormDialogService);

  submit(saveType: string) {
    if (saveType === SaveType.continue)
      this.dialogService.setMenuName('document');
  }
}
