import { HttpClient } from "@angular/common/http";
import { Component, computed, DestroyRef, inject, output, signal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { AuthUser } from "@app/core/auth/auth-user";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { AttachmentType } from "@app/pages/financials/shared/const/attachment-type.const";
import { LocalStorageApi } from "@app/shared/services/local-storage.service";
import { environment } from "@environments/environment";

@Component({
  selector: "app-global-form-contact-form-document",
  templateUrl: "./global-form-contact-form-document.component.html",
})
export class GlobalFormContactFormDocumentComponent {
  uploadBy: string = '';

  protected attachments = signal<any[]>([]);
  protected propertyId = signal<string>("");
  protected modelId = signal<string>("");
  protected attachmentModel = signal<AttachmentType | string>(AttachmentType.CUSTOMER);
  protected modelConfig = computed(() => {
    return {
      propertyId: this.propertyId()!,
      modelId: this.modelId(),
      model: this.attachmentModel(),
    };
  });

  protected userConfig = inject(UserConfigStore);
  protected storage = inject(LocalStorageApi);
  protected http = inject(HttpClient);
  #destroyRef = inject(DestroyRef);

  attachmentsChange = output<any[]>();

  constructor() {
    const globalAssociationId = toSignal(
      this.userConfig.selectedAssociationId$,
      { initialValue: "" }
    );
    this.propertyId.set(globalAssociationId() || "");
    this.modelId.set(crypto.randomUUID());
    this.uploadBy = this.storage.getItem<AuthUser>('user')?.name ?? '';
  }

  deleteFile(id: number): void {
    type Resp = { message: string };
    this.http.delete<Resp>(`${environment.apiUrl}/attachments/${id}`, {
      withCredentials: true,
    })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.attachments.update((file) => {
            return file.filter(t => t.id !== id);
          });
        },
        error: () => {
          // TODO: alert user
        },
      });
  }

  public submit(): void {
    this.attachmentsChange.emit(this.attachments());
  }
}
