<mat-accordion #accordionView [multi]="multi()">
  @for (item of data(); track item.id) {
    <mat-expansion-panel #panelView class="expansion-panel">
      <mat-expansion-panel-header class="expansion-header">
        <mat-panel-title>
          <span class="roam-text-normal">{{ item.name }}</span>
          <span roamNoPropagation class="roam-text-link ml-20">
            <a (click)="panelView.open()">Edit</a>
          </span>
          <span roamNoPropagation class="d-flex roam-ml-auto mr-10">
            <a
              (click)="onRemove(item)"
              inlineSVG="assets/svg/trash-red-icon.svg"></a>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansion-body">
        <div class="form-row mb-20">
          <app-roam-input
            name="Template Name"
            [(ngModel)]="item.name"
            placeholder="Enter the template name" />
        </div>
        <quill-editor
          class="content-editor"
          placeholder="Write your template body..."
          [required]="true"
          [(ngModel)]="item.text"
          [modules]="quillModules" />
      </div>
      <div class="edit-footer">
        <a (click)="cancel()" class="cancel-button fw-600">Cancel</a>
        <button
          (click)="submitEdit(item)"
          type="submit"
          class="submit-button btn btn-primary">
          Save
        </button>
      </div>
    </mat-expansion-panel>
  }
</mat-accordion>

@if (showAdd()) {
  <section class="add-mode-card">
    <div class="card-header">
      <p class="title">Add new template</p>
    </div>
    <form [formGroup]="form" (ngSubmit)="onAddSubmit()">
      <div class="card-body">
        <div class="form-row">
          <app-roam-input
            name="Template Name"
            formControlName="name"
            placeholder="Enter the template name" />
        </div>
        <quill-editor
          [required]="true"
          class="content-editor"
          placeholder="Write your template body..."
          formControlName="body"
          [modules]="quillModules" />
      </div>
      <div class="card-footer">
        <a (click)="toggleAdd(false)" class="cancel-button">Cancel</a>
        <button type="submit" class="submit-button btn btn-primary">
          Save
        </button>
      </div>
    </form>
  </section>
}

<button type="button" (click)="toggleAdd(true)" class="btn btn-o-primary mt-20">
  <a inlineSVG="assets/svg/plus-icon.svg"></a>
  <span>Add Template</span>
</button>
