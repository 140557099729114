import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  model,
  OnInit,
  signal,
  viewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { ConfirmDialogComponent, ConfirmDialogData } from "@app/pages/task/+ui";
import { TemplateModel } from "@app/pages/templates/+data-access/template.model";
import { TemplateService } from "@app/pages/templates/+data-access/template.service";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamNoPropagationDirective, scheduleTick } from "@app/utils";
import { InlineSVGModule } from "ng-inline-svg-2";
import { QuillEditorComponent } from "ngx-quill";
import { filter, first } from "rxjs";

@Component({
  standalone: true,
  selector: "app-violation-templates-accordion",
  styleUrl: "violation-templates-accordion.component.scss",
  templateUrl: "violation-templates-accordion.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    RoamNoPropagationDirective,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    QuillEditorComponent,
    RoamInputComponent,
    MatDialogModule,
    ConfirmDialogComponent,
  ],
})
export class ViolationTemplatesAccordionComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  fb = inject(NonNullableFormBuilder);
  readonly propertyId = input.required<string>();
  readonly data = model<TemplateModel[]>([]);
  readonly multi = input(false, { transform: booleanAttribute });
  readonly showAdd = signal(false);
  readonly accordionView = viewChild(MatAccordion);

  readonly quillModules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  protected form = this.fb.group({
    name: this.fb.control(""),
    body: this.fb.control(""),
  });

  get templateType() {
    return "violation-letter";
  }

  onRemove = (item: TemplateModel): void => {
    this.dialog
      .open(ConfirmDialogComponent, {
        backdropClass: "roam-dialog-backdrop",
        panelClass: "roam-dialog-panel",
        width: "700px",
        data: <ConfirmDialogData>{
          message: `Do you want to remove ${item.name}?`,
          title: "Remove Template",
          confirmLabel: "Confirm",
          cancelLabel: "Cancel",
        },
      })
      .afterClosed()
      .pipe(first(), filter(Boolean))
      .subscribe(() => {
        this.submitDelete(item.id);
      });
  };

  submitDelete = (id: string) => {
    this.templateApi
      .deleteTemplate(id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.data.update(list => {
            return list.filter(x => x.id !== id);
          });
          scheduleTick(() => {
            this.toastr.success("Succeeded!");
          });
        },
        error: () => {
          this.toastr.danger("Failed to remove this template!");
        },
      });
  };

  onAddSubmit = (): void => {
    const v = this.form.getRawValue();
    this.templateApi
      .createTemplate<TemplateModel>({
        propertyId: this.propertyId(),
        type: this.templateType,
        name: v.name,
        text: v.body,
      })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: resp => {
          if (resp) {
            this.data.update(list => {
              return [resp, ...list];
            });
          }
          scheduleTick(() => {
            this.toggleAdd(false);
            this.toastr.success("Succeeded");
            this.form.reset();
          });
        },
      });
  };

  toggleAdd(v?: boolean) {
    this.accordionView()?.closeAll();
    scheduleTick(() => {
      const next = v ?? !this.showAdd();
      this.showAdd.set(next);
    });
  }

  cancel() {
    this.accordionView()?.closeAll();
  }

  submitEdit(x: TemplateModel) {
    const id = x.id;
    this.templateApi
      .patchTemplate(id, {
        name: x.name,
        text: x.text,
      })
      .subscribe({
        error: () => {
          this.toastr.danger("Failed to update template!");
        },
        next: resp => {
          scheduleTick(() => this.toastr.info("Succeeded!"));
          this.data.update(list => {
            return list.map(item => {
              if (item.id === id) {
                item = {
                  ...item,
                  ...(resp || {}),
                  name: x.name,
                  text: x.text,
                };
              }
              return item;
            });
          });
        },
      });
  }

  loadTemplates = () => {
    this.templateApi
      .getTemplates<TemplateModel>({
        property: this.propertyId(),
        type: "violation-letter",
        limit: 10,
        page: 1,
      })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(resp => {
        this.data.set(resp.data || []);
      });
  };

  ngOnInit(): void {
    this.loadTemplates();
  }

  constructor(
    private dialog: MatDialog,
    private toastr: RoamToastrService,
    private templateApi: TemplateService
  ) {}
}
