<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Mailing Address</h1>

  <roam-icon
    mat-dialog-close
    class="c-pointer"
    name="close"
    size="28" />
</div>

<app-container-dialog-set (onSave)="submit($event)">
  <div container class="form-wrapper mt-16">
    <form [formGroup]="form">
      <div class="roam-form-row">
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('line1')">
          <app-roam-input formControlName="line1" name="Street 1" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('line2')">
          <app-roam-input formControlName="line2" name="Street 2" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
  
      <div class="roam-form-row">
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('city')">
          <app-roam-input formControlName="city" name="City" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('state')">
          <app-roam-input formControlName="state" name="State" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      
      <div class="roam-form-row">
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('country')">
          <app-roam-input formControlName="country" name="Country" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
        
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('zip')">
          <app-roam-input formControlName="zip" name="Zip Code, Ex: 11111" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
    </form>
  </div>
</app-container-dialog-set>
