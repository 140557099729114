<app-container-dialog-set (onClose)="close()" (onSave)="submit()">
  <ng-container container>
    <label class="fw-500">Violation Stages</label>
    <div class="mt-16">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        @for (data of listData; track data.id) {
          <div class="d-flex gap-16 f-column mb-16" cdkDrag>
            <app-violation-stage-item
              [data]="data"
              [index]="$index"
              (delete)="remove(data.id, $event)" />
          </div>
        }
      </div>
    </div>
    <div class="mt-16 mb-16">
      <app-button-add round label="Add Stage" (onAdd)="addStages()" />
    </div>
  </ng-container>
</app-container-dialog-set>
