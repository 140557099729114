import { InspectionFrequencyOptions } from './../../../../../../pages/inspection/+data-access/inspection.const';
import { Component, inject, signal } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { iconLib } from "@app/core/const/roam-icon";
import { IIdName } from '@app/shared/interfaces/id-name.interface';

@Component({
  selector: "app-global-form-association-compliance-inspections",
  templateUrl:
    "./global-form-association-compliance-inspections.component.html",
  styleUrls: [
    "./global-form-association-compliance-inspections.component.scss",
  ],
})
export class GlobalFormAssociationComplianceInspectionsComponent {
  
  public icon = iconLib;
  public isLoading = signal<boolean>(false);
  frequency: IIdName[] = InspectionFrequencyOptions;
  #fb = inject(FormBuilder)

  get formInspections(): FormArray {
    return this.form.get("inspectionsCategory") as FormArray;
  }
  
  form: FormGroup = this.#fb.group({
    role: [null, [Validators.required]],
    members: [null, [Validators.required]],
    frequency: [null, [Validators.required]]
  });

  submit(): any {}
  close(): any {}

}
