import {
    AfterViewInit,
    Component,
    DestroyRef,
    inject,
    Input,
    OnDestroy,
    signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { RoamOptionItem } from "@app/pages/_playground/roam-select.cmp";
import { askKingCancel } from "@app/pages/units/shared/const/unit-dialog.const";
import { IUnitDetail } from "@app/pages/units/shared/interface";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { AssociationService } from "@app/shared/services/association.service";
import { UnitService } from "@app/shared/services/unit.service";
import { distinctUntilChanged } from "rxjs";

@Component({
  selector: "app-global-form-unit-general-information",
  templateUrl: "./global-form-unit-general-information.component.html",
})
export class GlobalFormUnitGeneralInformationComponent
  implements AfterViewInit, OnDestroy
{
  #destroyRef = inject(DestroyRef);
  #unitDetail?: IUnitDetail;

  protected assocationOptions = signal<RoamOptionItem[]>([]);
  protected unitTypeOptions = signal<RoamOptionItem[]>([]);

  @Input("unitDetail")
  set unitDetail(v: IUnitDetail | undefined) {
    console.log("UNIT-DETAIL » ", v);
    if (v) {
      this.#unitDetail = v;
      this.form.patchValue(v);
    }
  }

  get unitDetail() {
    return this.#unitDetail;
  }

  readonly form = this.formBuilder.group({
    propertyId: ["", Validators.required],
    unitTypeId: [""],
    name: ["", Validators.required],
    slug: ["", Validators.required],
    restrooms: [""],
    bathrooms: [""],
    address: [""],
    latitude: [""],
    longitude: [""],
    maxOccupancy: [""],
    notes: [""],
    active: [false],
  });

  get controls() {
    return this.form.controls;
  }

  public yesNoOptions: any[] = [
    { code: "yes", id: true, name: "Yes" },
    { code: "no", id: false, name: "No" },
  ];

  ngOnDestroy(): void {
    if (this.form.dirty) {
      this.dialog.open(ConfirmationDialogComponent, {
        ...dialogConfig.confirmDialog,
        data: {
          confirmation: askKingCancel,
        },
      });
    }
  }

  submit(): void {
    const id = this.unitDetail?.id;
    const formValue = this.form.getRawValue();
    if (id) this.patchUnit(id, formValue);
    else this.addUnit(formValue);
  }

  protected addUnit = (body: any): void => {
    this.unitService
      .addUnit(body)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  };

  protected patchUnit = (id: string, body: any): void => {
    this.unitService
      .patchUnit(id, body)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  };

  close(): void {
    if (!this.form.dirty) {
      this.dialog.closeAll();
      return;
    }
    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirmDialog,
        data: {
          confirmation: askKingCancel,
        },
      })
      .afterClosed()
      .subscribe(val => {
        if (val) {
          this.submit();
        } else {
          this.dialog.closeAll();
        }
      });
  }

  getAssocationUnitTypes(associationId: string): void {
    console.log("fetching unit types with associationId » ", associationId);
    this.unitService
      .getUnitTypes(associationId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(resp => {
        const options = (resp.unitTypes || []).map(item => {
          return { id: item.id, name: item.name };
        });
        this.unitTypeOptions.set(options);
        if (options.length) {
          this.controls.unitTypeId.patchValue(options[0].id);
        }
      });
  }

  ngAfterViewInit(): void {
    this.controls.propertyId.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(associationId => {
        this.getAssocationUnitTypes(associationId || "");
      });
  }

  constructor(
    private associationApi: AssociationService,
    private unitService: UnitService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.associationApi
      .getAllAssocations()
      .pipe(takeUntilDestroyed())
      .subscribe(resp => {
        const options = (resp?.properties || []).map(x => {
          return {
            id: x.id,
            name: x.name,
          };
        });
        this.assocationOptions.set(options);
        const defaultAssociationId =
          (this.unitDetail as any)?.propertyId || options?.[0].id || "";
        this.controls.propertyId.patchValue(defaultAssociationId);
      });
  }
}
