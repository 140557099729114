import { Component } from '@angular/core';
import { ButtonAddComponent } from '@app/shared/components/button/button-add/button-add.component';
import { DropdownDirectiveModule } from '@app/shared/directives/dropdown-directive/dropdown-directive.module';
import { AddBoardMembersComponent } from './ui/add-board-members/add-board-members.component';

@Component({
  selector: 'app-global-form-association-board-members',
  standalone: true,
  imports: [ButtonAddComponent, AddBoardMembersComponent],
  template: `
    <h5 class="tc-black font-16ppx fw-500 ">List of Board of Directors</h5>
    <add-board-members />
  `,
  styles: ``
})
export class GlobalFormAssociationBoardMembersComponent {

}
