@switch (menu) {
  @case ("personal") {
    <app-global-form-contact-customer-personal
      [form]="controls.personal"
      (formSubmit)="mergeAndSubmit($event, 'resident')" />
  }
  @case ("resident") {
    <app-global-form-contact-form-resident
      [users]="contactDetail?.users || []"
      [form]="controls.resident"
      (formSubmit)="mergeAndSubmit($event, 'address')" />
  }
  @case ("address") {
    <app-global-form-contact-form-address
      [id]="contactDetail?.id"
      [addresses]="addresses()" />
  }
  @case ("property") {
    <app-global-form-contact-form-property />
  }
  @case ("document") {
    <app-global-form-contact-form-document
      (attachmentsChange)="attachmentsChangeHandler($event)" />
  }
}
