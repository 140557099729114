import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-global-form-contact-form-board-member-history",
  templateUrl: "./global-form-contact-form-board-member-history.component.html",
  styles: `
    .list-data {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 50px;
      gap: 10px;
      padding-bottom: 16px;
    }
    .icon-button {
      min-width: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 0.5rem;
      height: 2.5rem;
      width: 2.5rem;
      border: none;
      mat-icon {
        margin: 0;
      }
      .icon-btn-place {
        display: flex;
      }
    }
  `,
})
export class GlobalFormContactFormBoardMemberHistoryComponent {
  public submit(): void {}

  public form: FormGroup;

  get formBoardMembers(): FormArray {
    return this.form.get("boardMembers") as FormArray;
  }

  createFormBoard(): FormGroup {
    return this.formBuilder.group({
      role: [""],
      startDate: [""],
      endDate: [""],
    });
  }

  removeBoardMember(index: number): void {
    this.formBoardMembers.removeAt(index);
  }

  addBoardMember(): void {
    this.formBoardMembers.push(this.createFormBoard());
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      boardMembers: this.formBuilder.array([this.createFormBoard()]),
    });
  }
}
