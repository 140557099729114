import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { Component, signal } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { dropdownPosition } from "@app/core/const/overlay.const";
import { AvatarListInfoComponent } from "@app/shared/components/avatar-list-info/avatar-list-info.component";
import { ButtonAddComponent } from "@app/shared/components/button/button-add/button-add.component";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { InputSearchOneComponent } from "@app/shared/components/input/input-search-one/input-search-one.component";
import { TextAvatarLabelComponent } from "@app/shared/components/roam-text-label/text-avatar-label/text-avatar-label.component";

@Component({
  selector: "add-board-members",
  standalone: true,
  imports: [
    ButtonModule,
    OverlayModule,
    InputSearchOneComponent,
    MatCheckboxModule,
    TextAvatarLabelComponent,
  ],
  template: `
    <div class="mt-16"></div>
    <app-button-add
      label="Add Member"
      cdkOverlayOrigin
      #openProperty="cdkOverlayOrigin"
      (onAdd)="isDropdown.set(true)" />
    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="openProperty"
      [cdkConnectedOverlayOpen]="isDropdown()"
      cdkConnectedOverlayPush="true"
      [cdkConnectedOverlayWidth]="350"
      [cdkConnectedOverlayPositions]="dropdownPosition"
      [cdkConnectedOverlayViewportMargin]="7"
      (backdropClick)="isDropdown.set(false)">
      <div class="dropdown">
        <div class="pt-16 pr-16 pl-16">
          <app-input-search-one size="sm" (onSearch)="search($event)" />
          <h1 class="tc-black fw-600 mt-18">All Contacts</h1>
        </div>
        <div>
        <div class="hovered reverse dropdown-content-group">
          @for(user of [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3]; track $index) {
            <mat-checkbox>
              <app-text-avatar-label
                [image]="''"
                [title]="'Abby Doe'"
                [text]="'John Mail'"
                [type]="'title'" />
            </mat-checkbox>
          }
        </div>
        <div class="justify-between pt-10 plr-16 bdr-top">
          <button-cancel (cancel)="isDropdown.set(false)" />
          <app-roam-button label="Add"/>
        </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: `
    :host {
      margin-top: 20px;
    }
  `,
})
export class AddBoardMembersComponent {
  isDropdown = signal<boolean>(false);
  dropdownPosition: ConnectionPositionPair[] = dropdownPosition;

  search(keyword: string) {}
}
