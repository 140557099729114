<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onClose)="close()"
  (onSave)="submit()"
>
  <ng-container container [formGroup]="form">
   <div class="d-grid gap-20">
    <div class="d-grid gap-8">
      <h6 class="title">Who is responsible for inspections?</h6>
      <div class="set-grid set-grid-2">
        <app-roam-select placeholder="Select Role"
          formControlName="role"
          [ctr]="form.get('role')"
          [ctrName]="'role'"
          [items]="[
            {id: 'board', name: 'Board Members'},
            {id: 'management', name: 'Management Company'}
          ]"
        />

        @if(form.get('role')?.valid) {
          <app-roam-select placeholder="Assign To" 
            formControlName="members"
            [ctr]="form.get('members')"
            [ctrName]="'members'"
            [items]="frequency" 
            [isMultiple]="true" 
            [searchable]="true"
          />
        }
      </div>
    </div>
    <div class="d-grid gap-8">
      <h6 class="title">How often inspections should occur??</h6>
      <div class="set-grid set-grid-2">
        <app-roam-select placeholder="Select Frequency"
          formControlName="frequency"
          [ctr]="form.get('frequency')"
          [ctrName]="'frequency'"
          [items]="frequency"
        />
      </div>
    </div>
   </div>
  </ng-container>
</app-container-dialog-set>
