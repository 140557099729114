import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { configmDelete } from "@app/pages/associations/shared/const/association-dialog.const";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { ViolationsStageItem } from "@app/shared/components/violation-state-item/violation-stage-item.component";
import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";
import { scheduleTick } from "@app/utils";
import { filter, first } from "rxjs";

@Component({
  selector: "app-global-form-association-compliance-violations-stages",
  templateUrl:
    "./global-form-association-compliance-violations-stages.component.html",
  styleUrls: [
    "./global-form-association-compliance-violations-stages.component.scss",
  ],
})
export class GlobalFormAssociationComplianceViolationsStagesComponent {
  public form!: FormGroup;
  public listData: ViolationsStageItem[] = [
    {
      id: crypto.randomUUID(),
      stageName: "Open",
      templates: [],
      fineAmount: 200,
    },
    {
      id: crypto.randomUUID(),
      stageName: "1st notice of violation",
      templates: [
        {
          id: "0",
          name: "New violation notice",
        },
        {
          id: "1",
          name: "Updated violation notice",
        },
      ],
    },
    {
      id: crypto.randomUUID(),
      stageName: "2nd notice of violation",
      templates: [
        {
          id: "0",
          name: "New violation notice",
        },
      ],
    },
    {
      id: crypto.randomUUID(),
      stageName: "Hearing notice",
      templates: [],
    },
    {
      id: crypto.randomUUID(),
      stageName: "Owner resolved",
      templates: [
        {
          id: "1",
          name: "Updated violation notice",
        },
      ],
    },
  ];

  addStages(): void {
    const newStages: ViolationsStageItem = {
      id: crypto.randomUUID(),
      edit: true,
      stageName: "",
      templates: [],
    };

    this.listData.push(newStages);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.listData, event.previousIndex, event.currentIndex);
  }

  remove(id: string, withConfirmation = false): void {
    const confirmationDialogText: IConfirmationDialog = configmDelete;
    const callback = () => {
      this.listData = this.listData.filter(x => x.id !== id);
      if (withConfirmation) {
        scheduleTick(() => {
          this.toastr.success("Succeeded!");
        });
      }
    };
    if (!withConfirmation) {
      callback();
    } else {
      this.dialog
        .open(ConfirmationDialogComponent, {
          ...dialogConfig.confirm,
          data: {
            confirmation: confirmationDialogText,
          },
        })
        .afterClosed()
        .pipe(first(), filter(Boolean))
        .subscribe(() => {
          callback();
        });
    }
  }

  submit(): void {}

  close(): void {}

  constructor(
    public dialog: MatDialog,
    readonly toastr: RoamToastrService
  ) {}
}
