import { Component, DestroyRef, effect, inject, input, signal } from "@angular/core";
import { GlobalFormDialogService } from "../../../container/global-form-dialog/global-form-dialog.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { AuthStore } from "@app/core/auth";
import { AssociationService } from "@app/shared/services/association.service";
import { RoamToastrService } from "@app/pages/task/+data-access";

const SaveType = {
  save: 'save',
  continue: 'continue',
};

@Component({
  selector: "app-global-form-contact-form-address",
  templateUrl: "./global-form-contact-form-address.component.html",
  styles: `
    app-container-dialog-set {
      .roam-form-field {
          flex: 1;

          >* {
              width: 100%;
          }
      }
    }
  `
})
export class GlobalFormContactFormAddressComponent {
  id = input<string>('');
  addresses = input<any>(null);

  form: FormGroup = new FormGroup({
    modelId: new FormControl(''),
    modelType: new FormControl('Customer'),
    line1: new FormControl('', Validators.required),
    line2: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    zip: new FormControl('', Validators.required),
  });

  protected propertyId = signal<string>("");

  protected userConfig = inject(UserConfigStore);
  protected authStore = inject(AuthStore);
  protected readonly toastr = inject(RoamToastrService);
  dialogService = inject(GlobalFormDialogService);
  associationService = inject(AssociationService);
  #destroyRef = inject(DestroyRef);

  constructor() {
    const globalAssociationId = toSignal(
      this.userConfig.selectedAssociationId$,
      { initialValue: "" }
    );
    this.propertyId.set(globalAssociationId() || "");

    effect(() => {
      if (this.addresses()) this.initEdit();;
    })
  }

  private initEdit() {
    this.form.patchValue({
      modelId: this.addresses().modelId,
      modelType: this.addresses().modelType,
      line1: this.addresses().line1,
      line2: this.addresses().line2,
      city: this.addresses().city,
      state: this.addresses().state,
      country: this.addresses().country,
      zip: this.addresses().zip,
    })
  }

  fieldError(name: keyof typeof this.form.controls) {
    return this.form.controls[name].invalid && this.form.controls[name].touched;
  }

  public submit(saveType: string) {
    this.form.markAllAsTouched();
    this.form.get('modelId')?.setValue(this.id() ?? crypto.randomUUID());

    if (!this.form.valid) return;

    const bodyReq = {
      ...this.form.getRawValue(),
      ...{
        zip: +this.form.getRawValue().zip,
        propertyId: this.propertyId(),
      }
    }

    this.associationService.saveAssociationsAddress(bodyReq)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Mailing address created successfully!");

          if (saveType === SaveType.continue)
            this.dialogService.setMenuName('property');
        },
        error: () => {
          this.toastr.danger("Failed to create mailing address!");
        },
      });

  }
}
