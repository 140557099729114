<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Customer Account Info</h1>

  <roam-icon
    mat-dialog-close
    class="c-pointer"
    name="close"
    size="28" />
</div>

<app-container-dialog-set (onSave)="formSubmit.emit($event)">
  <div container class="form-wrapper">
    <form [formGroup]="form()">
      <div class="roam-form-row">
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('name')">
          <app-roam-input formControlName="name" name="Account Name" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      
      <div class="roam-form-row">
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('accountNumber')">
          <app-roam-input formControlName="accountNumber" name="Account Number" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
      
      <div class="roam-form-row">
        <div class="roam-form-field"
          [class.roam-form-field-error]="fieldError('generation')">
          <app-roam-input formControlName="generation" name="Generation" required />
          <p class="roam-form-error-message">This field is required</p>
        </div>
      </div>
    </form>
  </div>
</app-container-dialog-set>
