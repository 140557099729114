<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Property</h1>

  <roam-icon
    mat-dialog-close
    class="c-pointer"
    name="close"
    size="28" />
</div>

<app-container-dialog-set
  [isLoading]="isLoading()"
  [isValid]="true"
  (onSave)="submit($event)"
>
  <ng-container container>
    <div class="mt-16">
      <h6 class="font-18px fw-500 mb-8">List of Associated Properties</h6>
      <contact-select-property-dropdown />

      @if (store.propertiesList().length > 0) {
        <vendor-property-table />
      }
    </div>
  </ng-container>
</app-container-dialog-set>
