import { Component, input, output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

type FormType = FormGroup<{
  name: FormControl<string>;
  accountNumber: FormControl<string>;
  generation: FormControl<string>;
}>;

@Component({
  selector: "app-global-form-contact-customer-personal",
  styleUrl: "./global-form-contact-customer-personal.component.scss",
  templateUrl: "./global-form-contact-customer-personal.component.html",
})
export class GlobalFormContactCustomerPersonalComponent {
  readonly form = input.required<FormType>();
  readonly formSubmit = output<string>();

  fieldError(name: 'name' | 'accountNumber' | 'generation') {
    return this.form().controls[name].invalid && this.form().controls[name].touched;
  }
}
