import { Component, Input } from "@angular/core";
import { IAssociationDetail } from "@app/pages/associations/shared/interface";
import { GlobalFormTabsComponent } from "../../../container/global-form-tabs";
import { GlobalFormAssociationBoardMotionsComponent } from "../global-form-association-board-motions/global-form-association-board-motions.component";
import { GlobalFormAssociationBoardReportsComponent } from "../global-form-association-board-reports/global-form-association-board-reports.component";
import { CommonModule, NgSwitch } from "@angular/common";
import { GlobalFormAssociationBoardMembersComponent } from "../global-form-association-board-members/global-form-association-board-members.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    GlobalFormTabsComponent,
    GlobalFormAssociationBoardMotionsComponent,
    GlobalFormAssociationBoardReportsComponent,
    GlobalFormAssociationBoardMembersComponent
  ],
  selector: "app-global-form-association-board",
  templateUrl: "./global-form-association-board.component.html",
})
export class GlobalFormAssociationBoardComponent {
  @Input()
  public associationDetail?: IAssociationDetail;

  @Input("tab")
  public set setTab(tab: string | undefined) {
    this.tab = tab ?? this.tabs[0];
  }

  public tab: string;
  public tabs: string[] = ["motions", "reports", "members"];

  constructor() {
    this.tab = this.tabs[0];
  }
}
