import {
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  Input,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import {
  CustomerApiService,
  CustomerReqBody,
} from "@app/pages/customer/+data-access";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { filter } from "rxjs";
import { GlobalFormDialogService } from "../../../container/global-form-dialog/global-form-dialog.service";

const SaveType = {
  save: 'save',
  continue: 'continue',
};

@Component({
  selector: "app-global-form-contact-customer",
  templateUrl: "./global-form-contact-customer.component.html",
})
export class GlobalFormContactCustomerComponent {
  attachments: any[] = [];

  #destroyRef = inject(DestroyRef);
  #config = inject(UserConfigStore);
  #api = inject(CustomerApiService);
  #dialogService = inject(GlobalFormDialogService);
  protected readonly toastr = inject(RoamToastrService);
  protected readonly fb = inject(NonNullableFormBuilder);
  readonly isSubmitting = signal(false);
  readonly associationId = signal("");

  @Input()
  public contactDetail?: any;

  addresses = input<any>();

  @Input()
  public menu!: string;

  readonly form = this.fb.group({
    personal: this.fb.group({
      name: this.fb.control<string>("", Validators.required),
      accountNumber: this.fb.control<string>("", Validators.required),
      generation: this.fb.control<string>("", Validators.required),
    }),
    resident: this.fb.group({
      users: this.fb.control<{ id: string; roleSlug: string }[]>([]),
    }),
  });

  get controls() {
    return this.form.controls;
  }

  attachmentsChangeHandler(_attachments: any[]): void {
    this.attachments = _attachments;
    this.mergeAndSubmit(this.#dialogService.menuName() as string);
  }

  mergeAndSubmit(saveType: string, nextPage: string = ''): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) return;

    const id = this.contactDetail?.id;
    const users = this.controls.resident
      .getRawValue()
      .users.map(x => ({ id: x.id, roleSlug: x.roleSlug?.toLowerCase() }));
    if (id) {
      this.patchCustomerAccount(id, {
        propertyId: this.associationId(),
        users,
        ...this.controls.personal.getRawValue(),
        files: this.attachments,
      }, saveType, nextPage);
    } else {
      this.addCustomerAccount({
        id: crypto.randomUUID(),
        propertyId: this.associationId(),
        users,
        ...this.controls.personal.getRawValue(),
        files: this.attachments,
      }, saveType, nextPage);
    }
  }

  // TODO: set loading indicator ui!
  addCustomerAccount(props: CustomerReqBody.AddAccount, saveType: string, nextPage: string): void {
    console.log("Adding customer account with props » ", props);
    this.isSubmitting.set(true);
    this.#api
      .addAccount(props)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Customer account created successfully!");
          this.isSubmitting.set(false);

          if (nextPage && saveType === SaveType.continue)
            this.#dialogService.setMenuName(nextPage);
        },
        error: () => {
          this.toastr.danger("Failed to create customer account!");
          this.isSubmitting.set(false);
        },
      });
  }

  patchCustomerAccount(id: string, props: CustomerReqBody.PatchAccount, saveType: string, nextPage: string): void {
    console.warn(`Patching customer ${id} with props » `, props);
    this.isSubmitting.set(true);
    this.#api
      .patchAccount(id, props)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.success("Customer account updated successfully!");
          this.isSubmitting.set(false);

          if (nextPage && saveType === SaveType.continue)
            this.#dialogService.setMenuName(nextPage);
        },
        error: () => {
          this.toastr.danger("Failed to update customer account!");
          this.isSubmitting.set(false);
        },
      });
  }

  ngOnInit(): void {
    const prop = this.contactDetail;
    if (prop) {
      this.controls.personal.patchValue({
        name: prop.name,
        generation: prop.generation,
        accountNumber: prop.accountNumber,
      });
    }
  }

  constructor() {
    this.#config.selectedAssociationId$
      .pipe(filter(Boolean), takeUntilDestroyed())
      .subscribe(id => {
        this.associationId.set(id);
      });
  }
}
