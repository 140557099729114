<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Document</h1>

  <roam-icon
    mat-dialog-close
    class="c-pointer"
    name="close"
    size="28" />
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container>
    <h6 class="title mb-8">Uploaded Documents</h6>
    <div class="d-flex gap-16 f-column mb-16">
      @for (file of attachments(); track $index) {
        <app-file-card-bar
          [name]="file.name"
          [date]="(file.createdAt | date: 'MMMM dd, YYYY') || ''"
          [user]="uploadBy"
          [size]="file.size"
          (onDelete)="deleteFile(file.id)" />
      }
    </div>

    <hr class="mtb-24">

    <h6 class="title mb-8">Attachments</h6> 
    <app-file-uploader containerText="Drag Here" [(data)]="attachments" [config]="modelConfig()" [isShowFile]="false" />
  </ng-container>
</app-container-dialog-set>
