import { Component, DestroyRef, Input, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AssociationStore } from "@app/pages/associations/+data-access/association.store";
import { RoamToastrService } from "@app/pages/task/+data-access";
import { AssociationService } from "@app/shared/services/association.service";

@Component({
  selector: "app-global-form-association-general-amenities",
  templateUrl: "./global-form-association-general-amenities.component.html",
  styles: [
    `
      .boldText {
        font-weight: bold;
      }
    `,
  ]
})
export class GlobalFormAssociationGeneralAmenitiesComponent {
  @Input()
  public associationId?: string;

  @Input("associationAmenities")
  public set setAssociationAmenities(associationAmenities: any | undefined) {
    if (associationAmenities) this.generateForm(associationAmenities);
  }

  public form: FormGroup;
  store = inject(AssociationStore);
  #destroyRef = inject(DestroyRef);
  #dialogRef = inject(MatDialogRef)
  #toastr = inject(RoamToastrService);

  constructor(
    private associationService: AssociationService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({});
  }

  private generateForm(associationAmenities: any) {
    const services = this.store.services() as any;
    for (let key in associationAmenities) {
      this.form.addControl(key, new FormControl(services[key]));
    }
  }

  public submit() {
    const formData = this.form.value;

    // TODO : implement api here
    if (this.associationId) {
      const transformedData = {
        values: Object.entries(formData).map(([key, value]) => ({
          key,
          value,
        })),
      };

      this.associationService
        .saveAssociationServices(this.associationId, transformedData)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe({
          next: resp => {
            this.#toastr.success("Service & Amenities updated");
            this.store.updateServices(
              resp.reduce((acc: any, curr: any) => {
                acc[curr.key] = curr.value;
                return acc;
              }, {})
            );

            this.#dialogRef.close();
          },
        });
    } else {
      console.log("Association General Amenities Add");
    }

    console.log("DATA", formData);
  }
}
