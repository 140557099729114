<div class="justify-between align-center mb-30">
  <h1 class="font-24px">Board Member History</h1>

  <button mat-dialog-close class="btn btn-o-black pl-24 pr-24">Close</button>
</div>

<app-container-dialog-set (onSave)="submit()">
  <ng-container container [formGroup]="form">
    <div formArrayName="boardMembers" class="pt-16">
      <div
        class="list-data"
        *ngFor="
          let boardMember of formBoardMembers.controls;
          let formIndex = index
        "
        [formGroupName]="formIndex">
        <app-roam-select formControlName="role" placeholder="Role" />
        <app-roam-datepicker formControlName="startDate" name="Start Date" />
        <app-roam-datepicker formControlName="endDate" name="End Date" />

        <button
          *ngIf="formIndex != 0"
          (click)="removeBoardMember(formIndex)"
          mat-stroked-button
          class="icon-button"
          type="button">
          <div
            class="icon-btn-place"
            [inlineSVG]="'./assets/svg/trash-red-icon.svg'"></div>
        </button>
      </div>
      <app-button-add
        (click)="addBoardMember()"
        [label]="'Add Role'"
        [round]="true"></app-button-add>
    </div>
  </ng-container>
</app-container-dialog-set>
