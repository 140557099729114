import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RoamPaginatedResp } from "@app/shared/interfaces";
import { environment } from "@environments/environment";
import { TemplateReqBody } from "./template-req.model";

@Injectable({ providedIn: "root" })
export class TemplateService {
  constructor(private http: HttpClient) {}

  get url() {
    return `${environment.apiUrl}/templates`;
  }

  getTemplates = <Model = any>(options: TemplateReqBody.GetTemplates) => {
    type Resp = RoamPaginatedResp<Model>;
    return this.http.post<Resp>(`${this.url}/search`, options);
  };

  createTemplate = <Resp = any>(body: TemplateReqBody.CreateOne) => {
    return this.http.post<Resp>(`${this.url}`, body);
  };

  patchTemplate = <Resp = any>(id: string, body: any) => {
    return this.http.patch<Resp>(`${this.url}/${id}`, body);
  };

  // TODO:Ask BE for this endpoint?
  deleteTemplate = <Resp = null>(id: string) => {
    return this.http.delete<Resp>(`${this.url}/${id}`);
  };
}
