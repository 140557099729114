import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  model,
  OnInit,
  output,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import {
  dedupItems,
  RoamNoPropagationDirective,
  scheduleTick,
} from "@app/utils";
import { InlineSVGModule } from "ng-inline-svg-2";
import { RoamButtonComponent } from "../button/roam-button/roam-button.component";
import { RoamInputComponent } from "../roam-input/roam-input.component";
import { NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { RoamSelectComponent } from "../roam-select/roam-select.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import { startWith } from "rxjs";
import { TruncatePipe } from "@app/shared/pipes/truncate.pipe";

export interface ViolationTemplateOption {
  id: string;
  name: string;
}

export interface ViolationsStageItem {
  id: string;
  stageName: string;
  templates?: Array<ViolationTemplateOption>;
  edit?: boolean;
  fineAmount?: number;
}

const TEMPLATE_OPTIONS: ViolationTemplateOption[] = [
  {
    id: "1",
    name: "New violation template",
  },
  {
    id: "2",
    name: "Updated violation template",
  },
];

@Component({
  standalone: true,
  selector: "app-violation-stage-item",
  styleUrl: "violation-stage-item.component.scss",
  templateUrl: "violation-stage-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    TruncatePipe,
    ReactiveFormsModule,
    RoamInputComponent,
    RoamButtonComponent,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    RoamNoPropagationDirective,
    InlineSVGModule,
    RoamSelectComponent,
    MatTooltipModule,
  ],
})
export class ViolationStageItemComponent implements OnInit, AfterViewInit {
  #destroyRef = inject(DestroyRef);
  protected fb = inject(NonNullableFormBuilder);
  readonly index = input<number | null>(null);
  readonly data = model.required<ViolationsStageItem>();
  readonly showEdit = model(false);
  /**
   * if set to true means need confirmation!;
   */
  readonly delete = output<boolean>();

  readonly templateOptions = TEMPLATE_OPTIONS;

  readonly shorthandName = computed(() => {
    const index = this.index();
    const name = this.data().stageName;
    return (
      index !== null ? `${index + 1}`
      : name.length ? name[0].toUpperCase()
      : ""
    );
  });

  protected templateLabel = computed(() => {
    const data = this.data();
    if (!data.templates?.length) return "";
    return data.templates.map(x => x.name).join(",");
  });

  protected form = this.fb.group({
    stageName: this.fb.control<string>(""),
    templateIds: this.fb.control<string[]>([]),
    withFineAmount: this.fb.control<boolean>(false),
    fineAmount: this.fb.control<number>(0),
    triggersAbbatement: this.fb.control(false),
    automaticDriveThru: this.fb.control(false),
    repeatContinuously: this.fb.control(false),
    markInsurance: this.fb.control(false),
    markLeasing: this.fb.control(false),
  });

  get controls() {
    return this.form.controls;
  }

  get item() {
    return this.data();
  }

  toggleShowEdit = (v?: boolean) => {
    const next = v ?? !this.showEdit();
    this.showEdit.set(next);
  };

  protected onCancel = (): void => {
    if (this.data().edit === true) {
      this.delete.emit(false);
    } else {
      this.toggleShowEdit(false);
    }
  };

  onTemplateSave = (): void => {
    const stageName = this.controls.stageName.value;
    const ids = this.controls.templateIds.value;
    const templates = this.templateOptions.filter(x => {
      return ids.includes(x.id);
    });
    this.data.update(props => {
      return {
        ...props,
        stageName,
        templates,
      };
    });
    this.toggleShowEdit(false);
  };

  onCheckChange(id: string, e: MatCheckboxChange): void {
    const checked = e.checked;
    const existingIds = this.controls.templateIds.value;
    if (checked) {
      const ids = dedupItems([...existingIds, id]);
      this.controls.templateIds.patchValue(ids);
    } else {
      const ids = existingIds.filter(x => x !== id);
      this.controls.templateIds.patchValue(ids);
    }
  }

  onTemplatesAdd = (trigger: MatMenuTrigger): void => {
    const ids = this.controls.templateIds.value;
    const templates = this.templateOptions.filter(x => {
      return ids.includes(x.id);
    });
    this.data.update(props => {
      return {
        ...props,
        templates: templates,
      };
    });
    scheduleTick(() => {
      trigger.closeMenu();
    });
  };

  protected syncDataToForm = effect(() => {
    const data = this.data();
    this.controls.stageName.patchValue(data.stageName);
    if (data.templates) {
      this.controls.templateIds.patchValue(data.templates.map(x => x.id));
    }
  });

  ngAfterViewInit(): void {
    this.controls.withFineAmount.valueChanges
      .pipe(
        startWith(Boolean(this.data().fineAmount)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(withFineAmount => {
        const fineAmount = this.data().fineAmount || 0;
        if (withFineAmount) {
          this.controls.fineAmount.enable();
          this.controls.fineAmount.patchValue(fineAmount);
        } else {
          this.controls.fineAmount.patchValue(0);
          this.controls.fineAmount.disable();
        }
      });
  }

  ngOnInit(): void {
    const data = this.data();
    this.toggleShowEdit(data.edit === true);
    this.controls.stageName.patchValue(data.stageName || "");
    const templateIds = data.templates?.map(x => x.id) || [];
    this.controls.templateIds.patchValue(templateIds);
  }
}
