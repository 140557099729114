import { Injectable, signal } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AssociationStore {
	associationInfo = signal<any>({});
  readonly #state = {
    services: signal<any[]>([])
  }

  readonly services = this.#state.services.asReadonly();
  
  updateServices = (service: any) => {
    this.#state.services.update(services => {
      return {...services, ...service}
    })
  }
}